import React from "react"
import { Link } from "gatsby"
import Layout from "../components/Layout/layout"
import Header from "../components/Header/header"
import SEO from "../components/seo"
import "../assets/sass/theme/pages/terms.scss"
import CtaBanner from "../components/CtaBanner/CtaBanner"

const TermsPage = () => (
  <Layout>
    <SEO title="Terms and Conditions" url="terms-and-conditions" />
    <Header isHeaderSolid />
    <main className="terms with-spacing">
      <div className="page-width">
        <h1 className="title">Terms & Conditions</h1>
        <p>Insert terms and conditions content.</p>
        <Link to="/">Go back to the homepage</Link>
      </div>
    </main>
    <CtaBanner />
  </Layout>
)

export default TermsPage
